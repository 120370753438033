import _merge from 'lodash.merge';
import _unionby from 'lodash.unionby';

const initialState = {
    awards: undefined,
    careers: undefined,
    globalOptions: undefined,
    locations: undefined,
    news: undefined,
    pages: undefined,
    people: undefined
};

const rootReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'UPDATE_AWARDS':
            return {
                ...state,
                awards: action.data
            };

        case 'UPDATE_CAREERS':
            return {
                ...state,
                careers: action.data
            };

        case 'UPDATE_GLOBAL_OPTIONS':
            return {
                ...state,
                globalOptions: action.data
            };

        case 'UPDATE_LOCATIONS':
            return {
                ...state,
                locations: action.data
            };

        // Merge news data into news array, checking uniqueness by url key
        case 'UPDATE_NEWS':
            return {
                ...state,
                news: _unionby(state.news, action.data, 'url')
            };

        case 'UPDATE_PAGES':
            return _merge({}, state, { pages: action.data });

        case 'UPDATE_PEOPLE':
            return {
                ...state,
                people: action.data
            };

        default:
            return state;
    }
};

export default rootReducer;
