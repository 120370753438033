import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _get from 'lodash.get';

import { updatePages } from 'actions';
import { getPage } from 'api';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import Page from 'components/Page/Page';
import { siteTitle } from 'helpers';

const mapStateToProps = (state, ownProps) => {
    return {
        isHidden: !state.pages || !(ownProps.page in state.pages),
        pageDataLoaded: _get(state, `pages.${ownProps.page}.isLoaded`),
        background: _get(state, `pages.${ownProps.page}.background`),
        color: _get(state, `pages.${ownProps.page}.color`),
        description: _get(state, `pages.${ownProps.page}.description`),
        meta: _get(state, `pages.${ownProps.page}.meta`),
        title: _get(state, `pages.${ownProps.page}.title`)
    };
};

class PageContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: !props.pageDataLoaded
        };
    }

    async componentDidMount() {
        this._ismounted = true;

        if(!this.props.pageDataLoaded) {
            const pageData = await getPage(this.props.page);

            if(pageData) {
                this.props.dispatch(updatePages(pageData));

                if(this._ismounted) {
                    this.setState({
                        isLoading: false
                    });
                }
            }
            else {
                this.props.setNotFound();
            }
        }
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    render() {
        if(this.state.isLoading) {
            return <LoadingPage primary />;
        }

        // Pages that are just subpages should link back to home page
        const title = this.props.parentPage === 'home' && this.props.page !== 'home' ? 'Home' : this.props.title;
        const link = this.props.parentPage === 'home' && this.props.page !== 'home' ? '/' : `/${this.props.page}/`;

        return (
            <>
                {!this.props.subpageOpen &&
                    <Helmet>
                        <title>{`${this.props.title} | ${siteTitle}`}</title>
                        {this.props.meta && this.props.meta.description &&
                            <meta name="description" content={this.props.meta.description} />
                        }
                    </Helmet>
                }
                <Page
                    page={this.props.page}
                    background={this.props.background}
                    title={title}
                    link={link}
                    description={this.props.description}
                    color={this.props.color}
                    subpageOpen={this.props.subpageOpen}
                />
            </>
        );
    }
}

export default connect(mapStateToProps)(PageContainer);
