import React, { Component } from 'react';

import './OurPeople.scss';

class OurPeople extends Component {
    render() {
        return (
            <div className="o-our-people">
                <h2 className="o-our-people__headline">
                    Senior Management
                </h2>

                <div className="o-our-people__people">
                    {this.props.people && this.props.people.map((person, index) =>
                        <div key={index} className="c-person">
                            <div className="c-person__media">
                                <img src={person.image} alt={person.name} />
                            </div>
                            <span className="c-person__name">{person.name}</span>
                            <span className="c-person__subtitle">{person.jobTitle}</span>
                            <span className="c-person__description">{person.description}</span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default OurPeople;
