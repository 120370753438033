import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _get from 'lodash.get';

import { updatePages } from 'actions';
import { getPage } from 'api';
import SubPage from 'components/SubPage/SubPage';
import { siteTitle } from 'helpers';

const mapStateToProps = (state, ownProps) => {
    return {
        isHidden: !state.pages || !(ownProps.page in state.pages),
        meta: _get(state, `pages.${ownProps.page}.meta`),
        subpageData: _get(state, `pages.${ownProps.page}`),
        subpageDataLoaded: _get(state, `pages.${ownProps.page}.isLoaded`),
    };
};

class SubPageContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: !this.props.subpageDataLoaded
        };
    }

    async componentDidMount() {
        if(this.props.parentPage !== 'news') {
            if(!this.props.subpageData) {
                this.props.setNotFound();
            }

            if(!this.props.subpageDataLoaded) {
                const pageData = await getPage(this.props.page);

                if(pageData) {
                    this.props.dispatch(updatePages(pageData));
                }
            }
        }
    }

    loadingFinished = () => {
        this.setState({
            isLoading: false
        });
    }

    render() {
        return (
            <>
                <Helmet>
                    {this.props.subpageData && this.props.subpageData.title &&
                        <title>{`${this.props.subpageData.title} | ${siteTitle}`}</title>
                    }
                    {this.props.meta && this.props.meta.description &&
                        <meta name="description" content={this.props.meta.description} />
                    }
                </Helmet>
                <SubPage page={this.props.page} parentPage={this.props.parentPage} isLoading={this.state.isLoading} loadingFinished={this.loadingFinished} setNotFound={this.props.setNotFound} />
            </>
        );
    }
}

export default connect(mapStateToProps)(SubPageContainer);
