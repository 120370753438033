import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import './PageTitle.scss';

class PageTitle extends Component {
    render() {
        const pageTitleClass = classNames({
            'is-subpage-open': this.props.subpageOpen
        });

        return (
            <div className={`c-page-title ${pageTitleClass} is-color-${this.props.color}`}>
                <div className="c-page-title__link-wrapper">
                    <h1 className="c-page-title__title">{this.props.title}</h1>

                    <div className="c-page-title__back">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>

                    {this.props.subpageOpen &&
                        <Link to={this.props.link} className="c-page-title__link"></Link>
                    }
                </div>

                {this.props.description &&
                    <h2 className="c-page-title__description">{this.props.description}</h2>
                }
            </div>
        );
    }
};

export default PageTitle;
