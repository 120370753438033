import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _get from 'lodash.get';

import './LatestNews.scss';

const mapStateToProps = state => {
    return {
        isOpen: state.news && 0 in state.news,
        title: _get(state, 'news[0].title'),
        url: _get(state, 'news[0].url')
    };
};

class LatestNewsContainer extends Component {
    render() {
        const latestNewsClass = classNames({
            'is-open': this.props.isOpen && !this.props.subpageOpen
        });

        return (
            <div className={`c-latest-news ${latestNewsClass}`}>
                <div className="c-latest-news__container">
                    <span className="c-latest-news__label">Latest news</span>

                    <Link to={this.props.url || ''}>{this.props.title}</Link>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(LatestNewsContainer);
