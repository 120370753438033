import React, { Component } from 'react';

import './VideoPlaceholder.scss';

import playButton from './play_button.svg';

class VideoPlaceholder extends Component {
    render() {
        return (
            <div className="c-videoplaceholder">
                <img className="c-videoplaceholder__image" src={this.props.placeholderImage} alt="" />

                <div className="c-videoplaceholder__button" onClick={this.props.clickHandler}>
                    <img src={playButton} alt ="" />
                </div>
            </div>
        );
    }
}

export default VideoPlaceholder;
