import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './NewsLink.scss';

class NewsLink extends Component {
    render() {
        return (
            <Link to={this.props.url} className={`c-news-link is-color-${this.props.color}`}>
                <div className="c-news-link__image">
                    <img src={this.props.image} alt="" />
                </div>

                <div className="c-news-link__content">
                    <span className="c-news-link__date">Added {this.props.date}</span>
                    <span className="c-news-link__title">{this.props.title}</span>
                    <FontAwesomeIcon className="c-news-link__icon" icon={faArrowRight} />
                </div>
            </Link>
        );
    }
}

export default NewsLink;
