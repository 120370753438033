import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowLeft, faArrowRight, faArrowUp } from '@fortawesome/free-solid-svg-icons';

import './Button.scss';

class Button extends Component {
    getArrow = () => {
        switch(this.props.arrow) {
            case 'down':
                return <FontAwesomeIcon icon={faArrowDown} />;
            case 'left':
                return <FontAwesomeIcon icon={faArrowLeft} />;
            case 'right':
                return <FontAwesomeIcon icon={faArrowRight} />;
            case 'up':
                return <FontAwesomeIcon icon={faArrowUp} />;
            default:
                return null;
        }
    }

    render() {
        const buttonClass = classNames({
            'c-button': true,
            [`is-color-${this.props.color}`]: this.props.color
        });

        if(this.props.link) {
            return (
                <Link to={this.props.link} className={buttonClass}>
                    {this.props.text}
                    {this.getArrow()}
                </Link>
            );
        }

        return (
            <button className={buttonClass} onClick={this.props.onClick}>
                {this.props.text}
                {this.getArrow()}
            </button>
        );
    }
}

export default Button;
