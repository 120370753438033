import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _get from 'lodash.get';

import PageTitle from 'components/PageTitle/PageTitle';
import LatestNews from 'components/LatestNews/LatestNews';

import './Header.scss';

const mapStateToProps = state => {
    return {
        logo: _get(state, 'globalOptions.logo')
    };
};

class Header extends Component {
    render() {
        const headerClass = classNames({
            'is-subpage-open': this.props.subpageOpen,
            'o-header--mobile-left-align': this.props.page === 'home'
        });

        return (
            <header className={`o-header ${headerClass}`} style={{ backgroundImage: `url(${this.props.background})` }}>
                <Link to="/" className="o-header__logo">
                    <img src={this.props.logo || ''} alt="ABP" />
                </Link>

                <PageTitle
                    title={this.props.title}
                    link={this.props.link}
                    description={this.props.description}
                    color={this.props.color}
                    subpageOpen={this.props.subpageOpen}
                />

                <LatestNews subpageOpen={this.props.subpageOpen} />
            </header>
        );
    }
};

export default connect(mapStateToProps)(Header);
