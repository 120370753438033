import React, { Component } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash.get';

import PageLink from 'components/PageLink/PageLink';
import { basename } from 'helpers';

const mapStateToProps = (state, ownProps) => {
    const linkData = [];

    const links = _get(state, `pages.${ownProps.page}.links`, []);

    // Get data for each link from page data in store
    for(let i = 0, length = links.length; i < length; i++) {
        const link = links[i];

        // If external link just use given data
        if(link.url.indexOf('http') === 0) {
            linkData.push(link);
            continue;
        }

        // If internal link get page data from store
        const slug = basename(link.url);
        const pageData = state.pages[slug];

        if(!pageData) {
            continue;
        }

        linkData.push({
            ...link,
            title: pageData.title,
            color: pageData.color,
            image: pageData.icon,
            test: '',
        });
    }

    // Pad links array with dummy links until it is a mutiple of four, for flexbox reasons
    if(linkData.length > 4) {
        while(linkData.length % 4 !== 0) {
            linkData.push({
                isDummy: true,
                type: 'internal',
                title: '',
                url: '',
                color: '',
                image: '',
                test: '',
            });
        }
    }

    return {
        links: linkData
    };
};

class PageLinksContainer extends Component {
    render() {
        return (
            <nav className="o-page__links">
                {this.props.links.map((link, index) =>
                    <PageLink key={index} subpageOpen={this.props.subpageOpen} {...link} />
                )}
            </nav>
        );
    }
}

export default connect(mapStateToProps)(PageLinksContainer);
