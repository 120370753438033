import React, { Component } from 'react';

import VideoPlaceholder from 'components/VideoPlaceholder/VideoPlaceholder';

import './Sections.scss';

class Sections extends Component {
    constructor(props) {
        super(props);

        // If no custom thumbnail, just show video
        this.state = {
            autoplay: false,
            isVideoVisible: !props.videoThumbnail
        };
    }

    componentDidMount() {
        this.props.loadingFinished();
    }

    videoButtonClick = () => {
        this.setState({
            autoplay: true,
            isVideoVisible: true
        });
    }

    render() {
        return (
            <div className="c-sections">
                {this.props.video &&
                    <div className="full-container">
                        <div className="row">
                            <div className="column-10">
                                <div className="video-container">
                                    {this.state.isVideoVisible
                                        ? <iframe id="ytplayer" title="YouTube Video" src={`${this.props.video}?rel=0${this.state.autoplay ? '&autoplay=1' : ''}`} width="100%" height="100%" frameBorder="0" allowFullScreen></iframe>
                                        : <VideoPlaceholder placeholderImage={this.props.videoThumbnail} clickHandler={this.videoButtonClick} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {this.props.sections &&
                    <div className="container">
                        <div className="row">
                            <div className="column-10">

                                {this.props.sections.map((section, index) =>
                                    <div key={index} className="our-process-container">
                                        <div className="row">
                                            <div className="column-5 m-column-10">
                                                <span className="our-process-title">{section.title}</span>
                                                <div className={`our-process-subtitle is-color-${this.props.color}`} dangerouslySetInnerHTML={{__html: section.subtitle}} />
                                            </div>
                                            <div className="column-5 m-column-10">
                                                <div className="our-process-content" dangerouslySetInnerHTML={{__html: section.content}} />
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Sections;
