export const updateAwards = data => ({
    type: 'UPDATE_AWARDS',
    data: data
});

export const updateCareers = data => ({
    type: 'UPDATE_CAREERS',
    data: data
});

export const updateGlobalOptions = data => ({
    type: 'UPDATE_GLOBAL_OPTIONS',
    data: data
});

export const updateLocations = data => ({
    type: 'UPDATE_LOCATIONS',
    data: data
});

export const updateNews = data => ({
    type: 'UPDATE_NEWS',
    data: data
});

export const updatePages = data => ({
    type: 'UPDATE_PAGES',
    data: data
});

export const updatePeople = data => ({
    type: 'UPDATE_PEOPLE',
    data: data
});
