import React, { Component } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash.get';

import { updateNews } from 'actions';
import { getNewsArticle } from 'api';
import NewsArchive from 'components/NewsArchive/NewsArchive';
import NewsArticle from 'components/NewsArticle/NewsArticle';
import { formatNewsDate } from 'helpers';

const mapStateToProps = state => {
    return {
        color: _get(state, 'pages.news.color'),
        headerBackground: _get(state, 'news[0].image'),
        news: _get(state, 'news', [])
    };
};

class NewsContainer extends Component {
    async componentDidMount() {
        if(this.props.article) {
            // If article data not found, fetch from WordPress
            const newsDataExists = this.findArticleData(this.props.article);

            if(!newsDataExists) {
                const newsData = await getNewsArticle(this.props.article);

                // Update store with fetched data if found, otherwise show Not Found
                if(newsData) {
                    this.props.dispatch(updateNews(newsData));
                }
                else {
                    this.props.setNotFound();
                }
            }
        }
    }

    // Search through news props to find article by URL
    findArticleData(article) {
        const articleData = this.props.news.find(news => {
            return news.url === `/news/${article}/`;
        });

        return articleData;
    }

    render() {
        // Single news article view
        if(this.props.article) {
            const articleData = this.findArticleData(this.props.article);

            if(!articleData) {
                // this.props.setNotFound();
                return null;
            }

            return (
                <NewsArticle
                    parentPage={this.props.parentPage}
                    color={this.props.color}
                    image={articleData.image}
                    date={formatNewsDate(articleData.date)}
                    title={articleData.title}
                    excerpt={articleData.excerpt}
                    content={articleData.content}
                    podcast={articleData.podcast}
                    loadingFinished={this.props.loadingFinished}
                />
            );
        }

        // News articles archive page
        return (
            <NewsArchive parentPage={this.props.parentPage} color={this.props.color} loadingFinished={this.props.loadingFinished} />
        );
    }
}

export default connect(mapStateToProps)(NewsContainer);
