import React, { Component } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import Flickity from 'react-flickity-component';

import { updateAwards } from 'actions';
import { getAwards } from 'api';
import Award from 'components/Award/Award';

import './AwardsPage.scss';

const mapStateToProps = (state => {
    return {
        awards: _get(state, 'awards')
    };
});

class AwardsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeYear: null,
            years: []
        };
    }

    async componentDidMount() {
        // Fetch awards and put in store if not already present
        if(!this.props.awards) {
            const awards = await getAwards();
            this.props.dispatch(updateAwards(awards));

            this.props.loadingFinished();
        }

        // Create a list of all years where awards have been won and sort descendingly
        const years = [...new Set(this.props.awards.map(award => award.year))];
        years.sort();
        years.reverse();

        this.setState({
            activeYear: years[0],
            years: years
        });

        // Set active year to selected year in year slider
        this.yearSlider.on('change', index => {
            const selectedYear = this.yearSlider.selectedElement.textContent;

            this.setState({
                activeYear: selectedYear
            });
        });

        // Show selected award content when award slider updates
        this.awardSlider.on('ready', this.showSelectedAward);
        this.awardSlider.on('change', this.showSelectedAward);
    }

    /*
        This completely circumvents the normal React state flow. The award
        slider needs reloadOnUpdate to respond to the filtering caused by the
        year slider, but if it then also changes the React state on the change
        event it'll reset itself on every user interaction. This is no bueno,
        so use horrible DOM manipulation instead.
    */
    showSelectedAward = () => {
        const selectedAwardIndex = this.awardSlider.selectedElement.dataset.index;

        // Hide any visible awards
        document.querySelectorAll('.o-awards-page__award.is-visible').forEach(element => {
            element.classList.remove('is-visible');
        });

        // Show selected award
        const selectedAward = document.querySelector(`.o-awards-page__award[data-index="${selectedAwardIndex}"]`);
        if(selectedAward) {
            selectedAward.classList.add('is-visible');
        }
    };

    // Set active slide in year slider by index
    handleYearClick = index => {
        this.yearSlider.select(index);
    };

    render() {
        if(!this.props.awards) {
            return null;
        }
        return (
            <div className="o-awards-page">
                <Flickity
                    className="o-awards-page__years-slider"
                    options={{
                        pageDots: false,
                        prevNextButtons: false,
                        setGallerySize: false,
                    }}
                    flickityRef={ref => this.yearSlider = ref}
                    static
                >
                    {this.state.years.map((year, index) =>
                        <div key={index} className="o-awards-page__years-cell">
                            <span onClick={() => this.handleYearClick(index)} className="o-awards-page__year">{year}</span>
                        </div>
                    )}
                </Flickity>

                <Flickity
                    className="o-awards-page__awards-slider"
                    options={{
                        arrowShape: 'm57.5 92.2-5 5c-2.1 2.1-5.5 2.1-7.6 0l-43.3-43.4c-2.1-2.1-2.1-5.5 0-7.6l43.4-43.4c2.1-2.1 5.5-2.1 7.6 0l5 5c2.1 2.1 2.1 5.6-.1 7.7l-27 25.6h64.2c2.9 0 5.3 2.4 5.3 5.3v7.2c0 2.9-2.4 5.3-5.3 5.3h-64.2l26.9 25.6c2.2 2.1 2.2 5.6.1 7.7z',
                        cellSelector: '.is-visible',
                        pageDots: false,
                        selectedAttraction: 0.055,
                        setGallerySize: false
                    }}
                    flickityRef={ref => this.awardSlider = ref}
                    reloadOnUpdate
                    static
                >
                    {this.props.awards.map((award, index) =>
                        <div key={index} className={`o-awards-page__awards-cell ${award.year === this.state.activeYear ? 'is-visible' : ''}`} data-index={index}>
                            <img src={award.image} alt="" />
                        </div>
                    )}
                </Flickity>

                {/* Output all awards so above evil logic can show/hide them */}
                {this.props.awards.map((award, index) =>
                    <div key={index} className='o-awards-page__award' data-index={index}>
                        <Award
                            title={award.title}
                            summary={award.summary}
                            overview={award.overview}
                            categories={award.categories}
                            image={award.image}
                            modalBackground={this.props.modalBackground}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps)(AwardsContainer);
