import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

import { basename, siteTitle } from 'helpers';

import './Footer.scss';

const mapStateToProps = state => {
    return {
        address: state.globalOptions ? state.globalOptions.address : undefined,
        email: state.globalOptions ? state.globalOptions.email : undefined,
        fax: state.globalOptions ? state.globalOptions.fax : undefined,
        linkedin: state.globalOptions ? state.globalOptions.linkedin : undefined,
        links: state.globalOptions ? state.globalOptions.footerLinks : undefined,
        logo: state.globalOptions ? state.globalOptions.logo : undefined,
        pages: state.pages,
        telephone: state.globalOptions ? state.globalOptions.telephone : undefined,
        twitter: state.globalOptions ? state.globalOptions.twitter : undefined,
        youtube: state.globalOptions ? state.globalOptions.youtube : undefined,
        instagram: state.globalOptions ? state.globalOptions.instagram : undefined
    };
};

class Footer extends Component {
    render() {
        const footerClass = classNames({
            'o-footer--subpage': this.props.subpage
        });

        return (
            <footer className={`o-footer ${footerClass}`}>
                <div className="o-footer__icons">
                    <a className="o-footer__icon" href={this.props.twitter}>
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>

                    <div className="o-footer__separator"></div>

                    <a className="o-footer__icon" href={this.props.linkedin}>
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>

                    <div className="o-footer__separator"></div>

                    <a className="o-footer__icon" href={this.props.youtube}>
                        <FontAwesomeIcon icon={faYoutube} />
                    </a>

                    <div className="o-footer__separator"></div>

                    <a className="o-footer__icon" href={this.props.instagram}>
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                </div>

                <div className="o-footer__info">
                    <img className="o-footer__logo" src={this.props.logo || ''} alt="ABP" />

                    <div className="o-footer__content">
                        <nav className="o-footer__links">
                            {this.props.links && this.props.links.slice(0, 5).map((link, index) => {
                                // Only echo link if page exists
                                if(basename(link) in this.props.pages) {
                                    return <Link key={index} to={link}>
                                        {this.props.pages[basename(link)].title}
                                    </Link>;
                                }
                                return null;
                            })}
                        </nav>

                        <nav className="o-footer__links">
                            {this.props.links && this.props.links.slice(5).map((link, index) => {
                                // Only echo link if page exists
                                if(basename(link) in this.props.pages) {
                                    return <Link key={index} to={link}>
                                        {this.props.pages[basename(link)].title}
                                    </Link>;
                                }
                                return null;
                            })}
                        </nav>

                        <div className="o-footer__address">
                            {this.props.address && this.props.address.split('\n').map((item, key) => {
                                return <span key={key}>{item.replace('<br />', '')}</span>;
                            })}

                            <br />

                            {this.props.telephone &&
                                <span>
                                    T:&nbsp;
                                    <a href={`tel:${this.props.telephone}`}>
                                        {this.props.telephone}
                                    </a>
                                </span>
                            }
                            {this.props.fax &&
                                <span>
                                    F:&nbsp;
                                    <a href={`fax:${this.props.fax}`}>
                                        {this.props.fax}
                                    </a>
                                </span>
                            }
                            {this.props.email &&
                                <span>
                                    E:&nbsp;
                                    <a href={`mailto:${this.props.email}`}>
                                        {this.props.email}
                                    </a>
                                </span>
                            }
                        </div>
                    </div>

                    <div className="o-footer__copyright">
                        &copy; {siteTitle} {(new Date()).getFullYear()}
                    </div>
                </div>
            </footer>
        );
    }
};

export default connect(mapStateToProps)(Footer);
