import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _get from 'lodash.get';

import PageLinksContainer from 'containers/PageLinksContainer';

import './MenuSlide.scss';

const mapStateToProps = (state, ownProps) => {
    return {
        linksBackground: _get(state, `pages.${ownProps.page}.linksBackground`),
        template: _get(state, `pages.${ownProps.page}.template`)
    };
};

class MenuSlide extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isOpen: true
            });
        }, 500);
    }

    render() {
        const menuSlideClass = classNames({
            'is-open': this.state.isOpen
        });

        return (
            this.props.template === 'links' &&
                <>
                    <div className={`c-menu-slide__background ${menuSlideClass}`} style={{backgroundImage: `url(${this.props.linksBackground})`}}></div>

                    <div className={`c-menu-slide ${menuSlideClass}`} style={{backgroundImage: `url(${this.props.linksBackground})`}}>
                        <PageLinksContainer page={this.props.page} subpageOpen={this.props.subpageOpen} />
                    </div>
                </>
        );
    }
};

export default connect(mapStateToProps)(MenuSlide);
