import React, { Component } from 'react';
import classNames from 'classnames';

import './LoadingPage.scss';
import abplogo from './abp_logo.svg';

class Page extends Component {
    render() {
        const loadingClass = classNames({
            'o-loadingpage--primary': this.props.primary
        });

        return (
            <div className={`o-loadingpage ${loadingClass}`}>
                <div className="o-loadingpage__spinner"></div>
                <img className="o-loadingpage__logo" src={abplogo} alt="ABP" />
            </div>
        );
    }
};

export default Page;
