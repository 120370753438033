import React, { Component } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash.get';

import AwardContainer from 'components/Award/AwardContainer';
import Content from 'components/Content/Content';
import ContactContainer from 'components/ContactPage/ContactContainer';
import Footer from 'components/Footer/Footer';
import Generic from 'components/Generic/Generic';
import KeyStats from 'components/KeyStats/KeyStats';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import NewsContainer from 'containers/NewsContainer';
import OurPeopleContainer from 'components/OurPeople/OurPeopleContainer';
import Sections from 'components/Sections/Sections';
import SubPageHeader from 'components/SubPageHeader/SubPageHeader';

import './SubPage.scss';

const mapStateToProps = (state, ownProps) => {
    // Override page template to news if on a news page
    const template = (ownProps.page === 'news' || ownProps.parentPage === 'news') ? 'news' : _get(state, `pages.${ownProps.page}.template`);

    let showHeader = true;

    if(template === 'news' || ownProps.page === 'contact-us') {
        showHeader = false;
    }

    const breadcrumbs = ['home'];

    if(ownProps.parentPage !== 'home') {
        breadcrumbs.push(ownProps.parentPage);
    }

    const pageTitle = _get(state, `pages.${ownProps.page}.title`);
    breadcrumbs.push(pageTitle);

    return {
        isOpen: !!ownProps.page,
        background: _get(state, `pages.${ownProps.page}.headerImage`) || _get(state, `pages.${ownProps.page}.background`),
        breadcrumbs: breadcrumbs,
        color: _get(state, `pages.${ownProps.page}.color`),
        content: _get(state, `pages.${ownProps.page}.content`),
        description: _get(state, `pages.${ownProps.page}.description`),
        sections: _get(state, `pages.${ownProps.page}.sections`),
        video: _get(state, `pages.${ownProps.page}.video`),
        videoThumbnail: _get(state, `pages.${ownProps.page}.videoThumbnail`),
        showHeader: showHeader,
        stats: _get(state, `pages.${ownProps.page}.stats`),
        title: pageTitle,
        template: template
    };
};

class SubPage extends Component {
    // Render subpage content first based on template and then by page name
    componentContent = () => {
        switch(this.props.template) {
            case 'content':
                return  <Content
                            page={this.props.page}
                            color={this.props.color}
                            headline={this.props.content.headline}
                            intro={this.props.content.intro}
                            content={this.props.content.content}
                            imageblock={this.props.content.imageblock}
                            video={this.props.video}
                            videoThumbnail={this.props.videoThumbnail}
                            loadingFinished={this.props.loadingFinished}
                        />;

            case 'generic':
                return <Generic content={this.props.content} loadingFinished={this.props.loadingFinished} />;

            case 'news':
                // Pass through name of article or undefined if on news archive page
                const article = this.props.page === 'news' ? undefined : this.props.page;

                return <NewsContainer parentPage={this.props.parentPage} article={article} loadingFinished={this.props.loadingFinished} setNotFound={this.props.setNotFound} />;

            case 'sections':
                return <Sections color={this.props.color} sections={this.props.sections} video={this.props.video} videoThumbnail={this.props.videoThumbnail} loadingFinished={this.props.loadingFinished} />;

            case 'stats':
                return <KeyStats color={this.props.color} stats={this.props.stats} loadingFinished={this.props.loadingFinished} />;

            default:
        }

        switch(this.props.page) {
            case 'awards':
                return <AwardContainer modalBackground={this.props.background} loadingFinished={this.props.loadingFinished} />;

            case 'contact-us':
                return <ContactContainer parentPage={this.props.parentPage} color={this.props.color} title={this.props.title} description={this.props.description} loadingFinished={this.props.loadingFinished} />;

            case 'our-people':
                return <OurPeopleContainer loadingFinished={this.props.loadingFinished} />;

            default:
        }

        return null;
    }

    render() {
        return (
            <div className="o-subpage" data-subpage={this.props.page}>
                {this.props.isLoading &&
                    <LoadingPage />
                }

                <div style={this.props.isLoading ? {display: 'none'} : {}}>
                    {this.props.showHeader &&
                        <SubPageHeader
                            background={this.props.background}
                            color={this.props.color}
                            title={this.props.title}
                            description={this.props.description}
                            parentPage={this.props.parentPage}
                            breadcrumbs={this.props.breadcrumbs}
                        />
                    }

                    {this.componentContent()}

                    <Footer subpage />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(SubPage);
