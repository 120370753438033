import { createStore } from 'redux';

import { log } from 'helpers';
import rootReducer from './reducers';

const store = createStore(rootReducer);

if(process.env.REACT_APP_ENV !== 'production') {
    log('Initial store state:', 'green', store.getState());

    store.subscribe(() => log('State change:', 'green', store.getState()));
}

export default store;
