import React, { Component } from 'react';

import Header from 'components/Header/Header';
import MenuSlide from 'components/MenuSlide/MenuSlide';
import Footer from 'components/Footer/Footer';

import './Page.scss';

class Page extends Component {
    render() {
        return (
            <>
                <div className="o-page">
                    <Header
                        page={this.props.page}
                        background={this.props.background}
                        title={this.props.title}
                        link={this.props.link}
                        description={this.props.description}
                        color={this.props.color}
                        subpageOpen={this.props.subpageOpen}
                    />

                    <MenuSlide page={this.props.page} subpageOpen={this.props.subpageOpen} />
                </div>

                {!this.props.subpageOpen &&
                    <Footer />
                }
            </>
        );
    }
};

export default Page;
