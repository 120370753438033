import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import './KeyStats.scss';

class KeyStats extends Component {
    componentDidMount() {
        this.props.loadingFinished();
    }

    render() {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        return (
            <div className={`o-key-stats-page is-color-${this.props.color}`}>

                {this.props.stats && this.props.stats.map((stat, index) => {
                    return <div key={index} className="c-statistic">
                        <img className="c-statistic__image" src={stat.image} alt="" />

                        {isSafari
                            ? <div className="c-statistic__text">
                                <span className="c-statistic__number">{stat.statistic}</span>
                                <span className="c-statistic__suffix">{stat.suffix}</span>
                            </div>
                            : <Fade top distance="50%" fraction={0.5} delay={index === 0 ? 500: 0}>
                                <div className="c-statistic__text">
                                    <span className="c-statistic__number">{stat.statistic}</span>
                                    <span className="c-statistic__suffix">{stat.suffix}</span>
                                </div>
                            </Fade>
                        }
                    </div>;
                })}

            </div>
        );
    }
}

export default KeyStats;
