import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateNews } from 'actions';
import { getNews } from 'api';
import NewsLink from 'components/NewsLink/NewsLink';
import SubPageHeader from 'components/SubPageHeader/SubPageHeader';
import { formatNewsDate } from 'helpers';

import './NewsArchive.scss';

const mapStateToProps = (state, ownProps) => {
    return {
        news: state.news
    };
};

class NewsArchive extends Component {
    componentDidUpdate() {
        if(this.props.news.length) {
            this.props.loadingFinished();
        }
    }

    // Fetch more news items
    loadMoreNews = async event => {
        const offset = this.props.news.length;

        const news = await getNews(offset);
        this.props.dispatch(updateNews(news));
    }

    render() {
        if(!this.props.news || !this.props.news.length) {
            return null;
        }

        return (
            <div className="o-news-archive">
                <SubPageHeader
                    background={this.props.news[0].image}
                    color={this.props.color}
                    title={this.props.news[0].title}
                    titleLink={this.props.news[0].url}
                    description={formatNewsDate(this.props.news[0].date)}
                    parentPage={this.props.parentPage}
                    breadcrumbs={['home', 'News']}
                    isNewsArchive
                />

                <div className="o-news-archive__articles">
                    {this.props.news.slice(1).map((news, index) =>
                        <NewsLink key={index} color={this.props.color} title={news.title} url={news.url} date={formatNewsDate(news.date)} image={news.image} />
                    )}
                </div>

                <button className="o-news-archive__load-more" onClick={this.loadMoreNews}>
                    Load more
                </button>
            </div>
        );
    }
}

export default connect(mapStateToProps)(NewsArchive);
