import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './PageLink.scss';

class PageLink extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isClicked: false
        };

    }

    static contextTypes = Link.contextTypes;

    componentDidUpdate(prevProps) {
        if(this.props.subpageOpen && !prevProps.subpageOpen) {
            this.setState({
                isClicked: false
            });
        }
    }

    componentWillUnmount() {
        this.setState({
            isClicked: false
        });
    }

    // Delay link click to allow for animated circle transition
    handleInternalLinkClick = event => {
        if(event.defaultPrevented) {
            return;
        }
        event.preventDefault();

        this.setState({
            isClicked: true
        });

        setTimeout(() => {
            this.props.history.push(this.props.url);
        }, 300);
    }

    handleExternalLinkClick = event => {
        this.setState({
            isClicked: true
        });
    }

    render() {
        const pageLinkClass = classNames({
            'is-clicked': this.state.isClicked,
            'is-dummy': this.props.isDummy
        });

        const linkContent = (
            <>
                <div className={`c-page-link__background is-color-${this.props.color}`}></div>

                <div className="c-page-link__circle">
                    <img src={this.props.image || ''} alt="" className={this.props.special ? "c-page-link__special" : ""} />
                </div>

                <span className="c-page-link__label">{this.props.title}</span>
            </>
        );

        if(this.props.type === 'internal') {
            return (
                <Link to={this.props.url} className={`c-page-link ${pageLinkClass}`} onClick={this.handleInternalLinkClick}>
                    {linkContent}
                </Link>
            );
        }
        else if(this.props.type === 'external') {
            return (
                <a className={`c-page-link ${pageLinkClass}`} href={this.props.url} onClick={this.handleExternalLinkClick}>
                    {linkContent}
                </a>
            );
        }

        return null;
    }
};

export default withRouter(PageLink);
