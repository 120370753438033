import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import _get from 'lodash.get';

import { siteTitle } from 'helpers';

import './NotFound.scss';
import default404 from './default_404.jpg';
import defaultlogo from './default_logo.svg';

const mapStateToProps = state => {
    return {
        title: _get(state, 'globalOptions.404.title'),
        subtitle: _get(state, 'globalOptions.404.subtitle'),
        background: _get(state, 'globalOptions.404.background'),
        logo: _get(state, 'globalOptions.logo')
    };
};

class NotFound extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>{`Page not found | ${siteTitle}`}</title>
                </Helmet>
                <div className="c-notfound" style={{backgroundImage: `url(${this.props.background || default404})`}}>
                    <a href="/" className="c-notfound__logo">
                        <img src={this.props.logo || defaultlogo} alt="ABP" />
                    </a>

                    <div className="c-notfound__content">
                        <h1 className="c-notfound__title">{this.props.title || 'Sorry'}</h1>
                        <p>{this.props.subtitle || 'This page has mooved on to pastures new.'}</p>

                        <a href="/" className="c-notfound__link">
                            <FontAwesomeIcon icon={faArrowCircleRight} />
                            <br />
                            <span>Homepage</span>
                        </a>
                    </div>
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps)(NotFound);
