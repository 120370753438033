import React, { Component } from 'react';

import './ImageBlock.scss';

class ImageBlock extends Component {
    render() {
        return (
            <div className="c-imageblock">
                <img className="c-imageblock__image" src={this.props.background} alt="" />

                <div className={`c-imageblock__content is-color-${this.props.color}`}>
                    <h3 className="c-imageblock__title">{this.props.title}</h3>
                    <span>{this.props.content}</span>
                </div>
            </div>
        );
    }
}

export default ImageBlock;
