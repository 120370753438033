import _get from 'lodash.get';

import { absoluteToRelativeUrl, decodeHTMLEntities, getAPIURL, log } from 'helpers';

export async function getAllPages() {
    let response, json;

    try {
        response = await fetch(`${getAPIURL()}/wp-json/marvellous/v1/pagedata`);
        json = await response.json();
    }
    catch(error) {
        console.error(error);
        return false;
    }

    return json;
}

export async function getAwards() {
    let response, json;

    try {
        response = await fetch(`${getAPIURL()}/wp-json/wp/v2/award?_embed&per_page=100`);
        json = await response.json();
    }
    catch(error) {
        console.error(error);
        return false;
    }

    if(!json || json.length === 0) {
        return false;
    }

    const awards = json.map(element => ({
        title: decodeHTMLEntities(element.title.rendered),
        year: element.acf.award_year,
        summary: element.acf.award_summary,
        overview: decodeHTMLEntities(element.acf.award_overview),
        categories: decodeHTMLEntities(element.acf.award_categories),
        image: _get(element, '_embedded.wp:featuredmedia.[0].source_url')
    }));

    return awards;
}

export async function getCareers() {
    let response, json;

    try {
        response = await fetch(`${getAPIURL()}/wp-json/wp/v2/career?per_page=100`);
        json = await response.json();
    }
    catch(error) {
        console.error(error);
        return false;
    }

    if(!json || json.length === 0) {
        return false;
    }

    const careers = json.map(element => ({
        title: decodeHTMLEntities(element.title.rendered),
        content: decodeHTMLEntities(element.content.rendered),
        location: element.acf.career_location,
        division: element.acf.career_division,
        requirements: element.acf.career_requirements,
        pay: element.acf.career_pay,
        document: element.acf.career_file_download,
    }));

    return careers;
}

export async function getGlobalOptions() {
    let response, json;

    try {
        response = await fetch(`${getAPIURL()}/wp-json/acf/v3/options/options`);
        json = await response.json();
    }
    catch(error) {
        console.error(error);
        return false;
    }

    if(!json || !json.acf) {
        return false;
    }

    return {
        404: {
            title: json.acf['404_title'],
            subtitle: json.acf['404_subtitle'],
            background: json.acf['404_background']
        },
        address: json.acf.global_address,
        email: json.acf.global_email_address,
        fax: json.acf.global_fax_number,
        footerLinks: !json.acf.global_footer_links.length ? [] : json.acf.global_footer_links.map(link => absoluteToRelativeUrl(link)),
        linkedin: json.acf.global_linkedin_url,
        instagram: json.acf.global_instagram_url,
        logo: json.acf.global_logo,
        telephone: json.acf.global_telephone_number,
        twitter: json.acf.global_twitter_url,
        youtube: json.acf.global_youtube_url
    };
}

export async function getLocations() {
    log('API CALL LOCATIONS', 'blue');

    let response, json;

    try {
        response = await fetch(`${getAPIURL()}/wp-json/wp/v2/pages?slug=contact-us`);
        json = await response.json();
    }
    catch(error) {
        console.error(error);
        return false;
    }

    if(!json || json.length === 0) {
        console.error('getLocations returned zero results.');
        return false;
    }

    // Build array of location data
    const locationData = [];

    // Weird data structure comes from ACF clone field
    const locationGroups = {
        'Beef': 'contact_locations_beef',
        'Pet Food': 'contact_locations_pet_food.contact_locations_beef',
        'Proteins': 'contact_locations_proteins.contact_locations_beef',
        'Renewables': 'contact_locations_renewables.contact_locations_beef',
        'Sales Office': 'contact_locations_sales_office.contact_locations_beef'
    };

    for(const group in locationGroups) {
        const dataPath = locationGroups[group];

        _get(json[0].acf, dataPath).forEach(location => {
            locationData.push({
                group: group,
                name: location.location_name,
                address: location.location_address,
                telephone: location.location_telephone,
                latitude: location.location_latitude,
                longitude: location.location_longitude,
            });
        });
    }

    return locationData;
}

export async function getNews(offset = 0) {
    log(`API CALL NEWS (offset ${offset})`, 'blue');

    let response, json;

    try {
        response = await fetch(`${getAPIURL()}/wp-json/wp/v2/posts?per_page=5&_embed&offset=${offset}`);
        json = await response.json();
    }
    catch(error) {
        console.error(error);
        return false;
    }

    if(!json || json.length === 0) {
        return false;
    }

    const newsData = json.map(element => ({
        title: decodeHTMLEntities(element.title.rendered),
        url: absoluteToRelativeUrl(element.link),
        date: element.date,
        excerpt: decodeHTMLEntities(element.excerpt.rendered),
        content: element.content.rendered,
        image: _get(element, '_embedded.wp:featuredmedia.[0].media_details.sizes.banner.source_url') || element.jetpack_featured_media_url
    }));

    return newsData;
}

export async function getNewsArticle(slug) {
    log(`API CALL NEWS ARTICLE: ${slug}`, 'blue');

    let response, json;

    try {
        response = await fetch(`${getAPIURL()}/wp-json/wp/v2/posts?slug=${slug}`);
        json = await response.json();
    }
    catch(error) {
        console.error(error);
        return false;
    }

    if(!json || json.length === 0) {
        console.error('getNewsArticle returned zero results.');
        return false;
    }

    const newsData = json.map(element => ({
        title: decodeHTMLEntities(element.title.rendered),
        url: absoluteToRelativeUrl(element.link),
        date: element.date,
        excerpt: decodeHTMLEntities(element.excerpt.rendered),
        content: element.content.rendered,
        image: element.jetpack_featured_media_url,
        podcast: json[0].acf.article_podcast_link
    }));

    return newsData;
}

export async function getPage(slug) {
    log(`API CALL PAGE: ${slug}`, 'blue');

    let response, json;

    try {
        response = await fetch(`${getAPIURL()}/wp-json/wp/v2/pages?slug=${slug}`);
        json = await response.json();
    }
    catch(error) {
        console.error(error);
        return false;
    }

    if(!json || json.length === 0) {
        return false;
    }

    // Get name of template from template file path
    let templateName = json[0].template;
    if(templateName.substring(0, 24) === 'page-templates/template-') {
        templateName = templateName.slice(24);
    }
    if(templateName.substring(templateName.length - 4) === '.php') {
        templateName = templateName.slice(0, -4);
    }

    const pageData = {};

    pageData[slug] = {
        isLoaded: true,
        template: templateName,
        title: decodeHTMLEntities(json[0].title.rendered),
        description: decodeHTMLEntities(json[0].acf.page_description),
        color: json[0].acf.page_color,
        background: json[0].acf.page_background_image,
    };

    // Include extra fields based on page template
    switch(templateName) {
        case 'content':
            pageData[slug] = {
                ...pageData[slug],
                content: {
                    headline: json[0].acf.content_headline,
                    intro: json[0].acf.content_intro,
                    content: json[0].acf.content_content,
                    imageblock: {
                        title: _get(json, '[0].acf.content_image_block.image_block_title'),
                        content: _get(json, '[0].acf.content_image_block.image_block_content'),
                        background: _get(json, '[0].acf.content_image_block.image_block_background'),
                        linkTitle: _get(json, '[0].acf.content_image_block.image_block_link_title'),
                        linkURL: _get(json, '[0].acf.content_image_block.image_block_link_url'),
                    }
                },
                video: json[0].acf.content_video,
                videoThumbnail: json[0].acf.content_video_thumbnail
            };
            break;

        case 'generic':
            pageData[slug] = {
                ...pageData[slug],
                content: decodeHTMLEntities(json[0].acf.generic_content),
                headerImage: json[0].acf.generic_header_image
            };
            break;

        case 'links':
            pageData[slug] = {
                ...pageData[slug],
                linksBackground: json[0].acf.links_background_image,
                links: !json[0].acf.links.length ? [] : json[0].acf.links.map(link => {
                    if(link.link_type === 'internal') {
                        return {
                            type: 'internal',
                            url: absoluteToRelativeUrl(link.link_internal_page)
                        };
                    }
                    else if(link.link_type === 'external') {
                        console.log(link);
                        return {
                            type: 'external',
                            title: link.link_external_title,
                            url: link.link_external_url,
                            color: link.link_external_color,
                            image: link.link_external_icon,
                            special: link.external_link_special
                        };
                    }
                    return null;
                }),
            };
            break;

        case 'sections':
            pageData[slug] = {
                ...pageData[slug],
                sections: !json[0].acf.sections_sections.length ? [] : json[0].acf.sections_sections.map(section => ({
                    title: section.section_title,
                    subtitle: section.section_subtitle,
                    content: section.section_content
                })),
                video: json[0].acf.sections_video,
                videoThumbnail: json[0].acf.sections_video_thumbnail
            };
            break;

        case 'stats':
            pageData[slug] = {
                ...pageData[slug],
                stats: !json[0].acf.stats.length ? [] : json[0].acf.stats.map(stat => ({
                    statistic: stat.stat_statistic,
                    suffix: stat.stat_suffix,
                    image: stat.stat_image,
                }))
            };
            break;

        default:
    }

    // Include extra fields for specific pages
    switch(slug) {
        case 'contact-us':
            pageData[slug] = {
                ...pageData[slug],
                headerImage: json[0].acf.contact_header_image,
                pressContact: json[0].acf.contact_press_contact
            };
            break;

        default:
    }

    return pageData;
}

export async function getPeople() {
    log('API CALL PEOPLE', 'blue');

    let response, json;

    try {
        response = await fetch(`${getAPIURL()}/wp-json/wp/v2/senior_management?_embed&per_page=100&order=asc`);
        json = await response.json();
    }
    catch(error) {
        console.error(error);
        return false;
    }

    if(!json || json.length === 0) {
        return false;
    }

    const people = json.map(element => ({
        name: decodeHTMLEntities(element.title.rendered),
        description: decodeHTMLEntities(element.content.rendered),
        jobTitle: decodeHTMLEntities(element.acf.management_job_title),
        image: _get(element, '_embedded.wp:featuredmedia.[0].source_url')
    }));

    return people;
}
