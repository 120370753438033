import moment from 'moment';

// Converts an absolute URL to a relative URL by removing the hostname
export function absoluteToRelativeUrl(url) {
    try {
        return (new URL(url)).pathname;
    }
    catch {
        return url;
    }
}

// Returns last component of path
export function basename(path) {
    // Remove trailing slash
    if(path.endsWith('/')) {
        path = path.slice(0, -1);
    }

    // Return everything after last /
    return path.slice(path.lastIndexOf('/') + 1);
}

// Decode any encoded HTML entities in a string
export function decodeHTMLEntities(text) {
    if(!text) {
        return text;
    }

    const elementMap = {
        '&amp;': '&',
        '&gt;': '>',
        '&hellip;': '…',
        '&lt;': '<',
        '&quot;': '"',
        '&#038;': '&',
        '&#039;': "'",
        '&#8211;': '–',
        '&#8212;': '—',
        '&#8216;': '‘',
        '&#8217;': '’',
        '&#8220;': '“',
        '&#8221;': '”',
        '&#8230;': '…'
    };

    return text.replace(/&[\w\d#]{2,6};/g, match => elementMap[match] || '');
}

export function formatNewsDate(date) {
    return moment(date).format('DD.MM.YY');
}

export function getAPIURL() {
    switch(window.location.origin) {
        case 'http://localhost:3000':
            return 'http://abpfoodgroup.test';

        default:
            return window.location.origin;
    }
}

// Only log to console in non-production environments
export function log(message, color, ...args) {
    if(process.env.REACT_APP_ENV !== 'production') {
        console.log(`%c${message}`, `color: ${color};`, ...args);
    }
}

export const siteTitle = 'ABP Food Group';
