import React, { Component } from 'react';
import { GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';

import './ContactPage.scss';

const MapComponent = withScriptjs(withGoogleMap(props =>
    <GoogleMap defaultZoom={5} defaultCenter={{ lat: 55.3780510, lng: -3.4359730 }} onClick={() => props.selectMarker(null)} options={{ gestureHandling: 'cooperative' }}>
        {props.locations && props.locations.map((location, index) =>
            <Marker key={index} position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }} onClick={() => props.selectMarker(index)}>
                {/* Draw InfoWindow if index matches index of selected Marker */}
                {props.selectedMarker !== index
                    ? null
                    : <InfoWindow onCloseClick={() => props.selectMarker(null)}>
                        <div className="o-contact-page__map-infowindow">
                            <strong>{location.name}</strong>
                            <span>{location.address}</span>
                            {location.telephone &&
                                <span>Tel: {location.telephone}</span>
                            }
                        </div>
                    </InfoWindow>
                }
            </Marker>
        )}
    </GoogleMap>
));

class ContactPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeGroup: 'All',
            selectedMarker: null,
            selectOpen: false
        };
    }

    setActiveGroup = group => {
        if(group !== this.state.activeGroup) {
            this.setState({
                activeGroup: group
            });

            // Also clear any open InfoWindows
            this.selectMarker(null);
        }

        // Close select box on selection
        this.setState({
            selectOpen: false
        });
    }

    // Get locations with given group name
    getFilteredLocations = filter => {
        if(filter === 'All') {
            return this.props.locations;
        }

        // Find all locations with given group
        return this.props.locations.filter(location => location.group === filter);
    }

    selectMarker = markerID => {
        this.setState({
            selectedMarker: markerID
        });
    }

    // Select the Google Maps Marker and scroll so map is visible
    handleAddressClick = (markerID, event) => {
        this.selectMarker(markerID);

        // Scroll parent subpage to top of map
        event.currentTarget.closest('.o-subpage').scrollTo({
            top: document.getElementById('js-contact-page').offsetTop,
            behavior: 'smooth'
        });
    }

    toggleSelect = event => {
        this.setState(state => ({
            selectOpen: !state.selectOpen
        }));
    }

    render() {
        return (
            <div id="js-contact-page" className="o-contact-page">
                <div className={`contact-navigation is-color-${this.props.color}`}>
                    <div className="contact-select" onClick={() => this.toggleSelect()}>
                        <span className="contact-select-content">{this.state.activeGroup}</span>
                        <i className="utl-chevron utl-chevron--down"></i>
                    </div>
                    <ul className={`contact-nav ${this.state.selectOpen ? '' : 'contact-nav-hide'}`}>
                        {this.props.groups.map(group =>
                            <li key={group} className={group === this.state.activeGroup ? 'active' : ''} onClick={() => this.setActiveGroup(group)}>
                                <span>{group}</span>
                            </li>
                        )}
                    </ul>
                </div>

                <MapComponent
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                    loadingElement={<div style={{ height: '100%' }} />}
                    containerElement={<div style={{ height: '500px' }} />}
                    mapElement={<div style={{ height: '100%' }} />}
                    locations={this.getFilteredLocations(this.state.activeGroup)}
                    selectedMarker={this.state.selectedMarker}
                    selectMarker={this.selectMarker}
                />

                <div className="contact-addresses">
                    <h2 className="address-title">Addresses</h2>

                    <div className="contact-address-list">
                        {this.getFilteredLocations(this.state.activeGroup) && this.getFilteredLocations(this.state.activeGroup).map((location, index) =>

                            <div key={index} className={`contact-address is-color-${this.props.color}`} onClick={(event) => this.handleAddressClick(index, event)}>
                                <div className="row">
                                    <div className="column-5">
                                        <span className="address-name">{location.name}</span>
                                        <span className="address-place">{location.group}</span>
                                    </div>
                                    <div className="column-5">
                                        <div className="row">
                                            <div className="column-6 m-column-10">
                                                <span className="address-place">{location.address}</span>
                                            </div>
                                            <div className="column-4 m-column-10">
                                                <span className="address-phone">{location.telephone}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )}
                    </div>
                </div>

                {this.props.pressContact &&
                    <div className="o-contact-page__press">
                        <h2 className="address-title">Press contact</h2>

                        <div dangerouslySetInnerHTML={{__html: this.props.pressContact}}></div>
                    </div>
                }
            </div>
        );
    }
}

export default ContactPage;
