import React, { Component } from 'react';

import CareersContainer from 'components/Careers/CareersContainer';
import ImageBlock from 'components/ImageBlock/ImageBlock';
import VideoPlaceholder from 'components/VideoPlaceholder/VideoPlaceholder';

import './Content.scss';

class Content extends Component {
    constructor(props) {
        super(props);

        // If no custom thumbnail, just show video
        this.state = {
            autoplay: false,
            isVideoVisible: !props.videoThumbnail
        };
    }

    componentDidMount() {
        this.props.loadingFinished();
    }

    videoButtonClick = () => {
        this.setState({
            autoplay: true,
            isVideoVisible: true
        });
    }

    render() {
        return (
            <>
                <article className="c-content">
                    <div className="c-content__intro">
                        {this.props.headline &&
                            <h2 className="c-content__headline">{this.props.headline}</h2>
                        }
                        {this.props.intro &&
                            <div className="c-content__intro-text" dangerouslySetInnerHTML={{ __html: this.props.intro }} />
                        }
                    </div>

                    <div className="c-content__content" dangerouslySetInnerHTML={{ __html: this.props.content }} />

                    {this.props.page === 'current-vacancies' &&
                        <CareersContainer />
                    }
                </article>

                {this.props.imageblock && (this.props.imageblock.title || this.props.imageblock.content) &&
                    <ImageBlock
                        color={this.props.color}
                        title={this.props.imageblock.title}
                        content={this.props.imageblock.content}
                        background={this.props.imageblock.background}
                    />
                }

                {this.props.video &&
                    <div className="video-container">
                        {this.state.isVideoVisible
                            ? <iframe id="ytplayer" title="YouTube Video" src={`${this.props.video}?rel=0${this.state.autoplay ? '&autoplay=1' : ''}`} width="100%" height="100%" frameBorder="0" allowFullScreen></iframe>
                            : <VideoPlaceholder placeholderImage={this.props.videoThumbnail} clickHandler={this.videoButtonClick} />
                        }
                    </div>
                }
            </>
        );
    }
}

export default Content;
