import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import MediaQuery from 'react-responsive';
import _get from 'lodash.get';

import Button from 'components/Button/Button';

import './Award.scss';
import './AwardModal.scss';
import closeButton from './close.svg';

Modal.setAppElement('#root');

const mapStateToProps = state => {
    return {
        logo: _get(state, 'globalOptions.logo')
    };
};

class Award extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false
        };
    }

    openModal = () => {
        this.setState({ isModalOpen: true });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    render() {
        const modalStyles = {
            content: {
                backgroundImage: `url(${this.props.modalBackground})`
            },
            overlay: {
                zIndex: 2
            }
        };

        return (
            <div className="c-award">
                <h2 className="c-award__title">{this.props.title}</h2>

                <hr />

                <div className="c-award__summary">
                    {this.props.summary}
                </div>

                <Button onClick={this.openModal} text="Find out more" color="red" arrow="right" />

                <Modal
                    className="c-award-modal"
                    contentLabel={this.props.title}
                    isOpen={this.state.isModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={modalStyles}
                >
                    <img className="c-award-modal__logo" src={this.props.logo || ''} alt="ABP" />

                    <button onClick={this.closeModal} className="c-award-modal__close" aria-label="Close award">
                        <img src={closeButton} alt="" />
                    </button>

                    <div className="c-award-modal__content">
                        <div className="c-award-modal__column">
                            <img className="c-award-modal__image" src={this.props.image} alt="" />

                            <MediaQuery minWidth={751}>
                                <Button onClick={this.closeModal} text="See all awards" color="red" arrow="right" />
                            </MediaQuery>
                        </div>

                        <div className="c-award-modal__column">
                            <h2 className="c-award-modal__title">{this.props.title}</h2>

                            {this.props.overview &&
                                <div className="c-award-modal__section">
                                    <h3>Overview</h3>
                                    <div dangerouslySetInnerHTML={{__html: this.props.overview}} />
                                </div>
                            }

                            {this.props.categories &&
                                <div className="c-award-modal__section">
                                    <h3>Categories</h3>
                                    <div dangerouslySetInnerHTML={{__html: this.props.categories}} />
                                </div>
                            }

                            <MediaQuery maxWidth={750}>
                                <Button onClick={this.closeModal} text="See all awards" color="red" arrow="right" />
                            </MediaQuery>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Award);
