import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import SubPageHeader from 'components/SubPageHeader/SubPageHeader';

import './NewsArticle.scss';

class NewsArticle extends Component {
    componentDidMount() {
        this.props.loadingFinished();
    }

    render() {
        return (
            <>
                <SubPageHeader
                    background={this.props.image}
                    color={this.props.color}
                    title={this.props.title}
                    parentPage={this.props.parentPage}
                    breadcrumbs={['home', 'news', this.props.title]}
                    isNewsArticle
                />
                <article className="c-article">
                    <span className="c-article__date">Added {this.props.date}</span>

                    <h2 className="c-article__title">{this.props.title}</h2>

                    <h4 className="c-article__excerpt">{this.props.excerpt}</h4>

                    <div className="c-article__content" dangerouslySetInnerHTML={{ __html: this.props.content }} />

                    {this.props.podcast &&
                        <a className = "c-article__promo"  href="https://www.youtube.com/watch?v=AXi_0CDvupc">
                            <div className="c-article__promo-message">
                                Watch and listen to our podcast "Now We're Talking Farming"
                            </div>

                            <div className="c-article__promo-button c-button is-color-red">
                                Listen now
                                <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </a>
                    }
                </article>
            </>
        );
    }
}

export default NewsArticle;
