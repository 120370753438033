import React, { Component } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash.get';

import { updateCareers } from 'actions';
import { getCareers } from 'api';
import Careers from 'components/Careers/Careers';

const mapStateToProps = (state => {
    return {
        careers: _get(state, 'careers')
    };
});

class CareersContainer extends Component {
    async componentDidMount() {
        // Fetch careers and put in store if not already present
        if(!this.props.careers) {
            const careers = await getCareers();

            this.props.dispatch(updateCareers(careers));
        }
    }

    render() {
        return (
            <Careers careers={this.props.careers} />
        );
    }
}

export default connect(mapStateToProps)(CareersContainer);
