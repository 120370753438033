import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { updateGlobalOptions, updateNews, updatePages } from 'actions';
import { getAllPages, getGlobalOptions, getNews } from 'api';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import NotFound from 'components/NotFound/NotFound';
import PageContainer from 'components/Page/PageContainer';
import SubPageContainer from 'components/SubPage/SubPageContainer';

import 'normalize.css';
import './App.scss';

let currentRoute = '';

switch(process.env.REACT_APP_ENV) {
    case 'development':
        ReactGA.initialize('testing', {
            testMode: true
        });
        break;

    case 'staging':
        ReactGA.initialize('UA-54734123-2');
        break;

    case 'production':
        ReactGA.initialize('UA-54734123-1');
        break;

    default:
}

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isNotFound: false
        };
    }

    async componentDidMount() {
        // Get initial data for all pages and global options in parallel
        const apiCalls = await Promise.all([
            getAllPages(),
            getGlobalOptions()
        ]);

        if(apiCalls[0]) {
            this.props.dispatch(updatePages(apiCalls[0]));
        }
        if(apiCalls[1]) {
            this.props.dispatch(updateGlobalOptions(apiCalls[1]));
        }

        this.setState({
            isLoading: false
        });

        // Get latest news
        const newsData = await getNews();
        this.props.dispatch(updateNews(newsData));
    }

    setNotFound = () => {
        this.setState({
            isNotFound: true
        });
    }

    // Get first segment of location pathname
    getParentPathname = pathname => {
        if(pathname === '/') {
            return '';
        }

        const splitPathname = (/(\w)([^/]*)/).exec(pathname);

        if(!splitPathname) {
            return '';
        }

        return splitPathname[0];
    }

    render() {
        if(this.state.isLoading) {
            return (
                <LoadingPage />
            );
        }

        return (
            <BrowserRouter>
                <Route render={({ location }) => {
                    const nextRoute = this.getParentPathname(location.pathname);
                    const isAnimated = !currentRoute.includes(nextRoute) || !nextRoute.includes(currentRoute);

                    currentRoute = nextRoute;

                    ReactGA.pageview(window.location.pathname + window.location.search);

                    return <TransitionGroup className="page-transition-group">
                        <CSSTransition key={nextRoute} classNames="page-transition" timeout={500} enter={isAnimated} exit={isAnimated}>
                            <div id={location.pathname} className="page-wrapper">
                                <Switch location={location}>
                                    {this.state.isNotFound &&
                                        <Route component={NotFound} />
                                    }

                                    <Route path="/:page?/:subpage?" render={props => {
                                        const page = props.match.params.page || 'home';
                                        let subpage = props.match.params.subpage;
                                        let parentPage = page;

                                        // Display certain top-level pages as subpages
                                        if(!subpage && ['contact-us', 'cookies-policy', 'news', 'privacy-policy'].includes(page)) {
                                            subpage = page;
                                            parentPage = 'home';
                                        }

                                        const isSubpageOpen = !!subpage;

                                        return <>
                                            <PageContainer key={this.getParentPathname(props.location.pathname)} page={page} parentPage={parentPage} subpageOpen={isSubpageOpen} setNotFound={this.setNotFound} />

                                            <TransitionGroup className="subpage-transition-group">
                                                <CSSTransition key={subpage} classNames="subpage-transition" timeout={{enter: 500, exit: 500}} appear={isSubpageOpen}>
                                                    {isSubpageOpen
                                                        ? <SubPageContainer page={subpage} parentPage={parentPage} setNotFound={this.setNotFound} />
                                                        : <div></div>
                                                    }
                                                </CSSTransition>
                                            </TransitionGroup>
                                        </>;
                                    }} />
                                </Switch>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>;
                }} />
            </BrowserRouter>
        );
    }
}

export default connect()(App);
