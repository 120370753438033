import React, { Component } from 'react';

import './Generic.scss';

class Generic extends Component {
    componentDidMount() {
        this.props.loadingFinished();
    }

    render() {
        return (
            <div className="c-generic" dangerouslySetInnerHTML={{ __html: this.props.content }} />
        );
    }
};

export default Generic;
