import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _get from 'lodash.get';

import './SubPageHeader.scss';

const mapStateToProps = (state, ownProps) => {
    const breadcrumbs = [];

    if(ownProps.breadcrumbs) {
        for(let i = 0, length = ownProps.breadcrumbs.length; i < length; i++) {
            const breadcrumb = ownProps.breadcrumbs[i];

            // All breadcrumbs except last should be links
            if(i !== length - 1) {
                if(breadcrumb === 'home') {
                    breadcrumbs.push(<Link key={i} to="/">Home</Link>);
                }
                else {
                    const pageTitle = _get(state, `pages.${breadcrumb}.title`);

                    breadcrumbs.push(<Link key={i} to={`/${breadcrumb}/`}>{pageTitle}</Link>);
                }

                breadcrumbs.push(' > ');
            }
            else {
                breadcrumbs.push(<span key={i} className={`c-subpage-header__current-page is-color-${ownProps.color}`}>{breadcrumb}</span>);
            }
        }
    }

    return {
        breadcrumbs: breadcrumbs,
        logo: _get(state, 'globalOptions.logo'),
        parentPageLink: ownProps.parentPage === 'home' ? '/' : `/${ownProps.parentPage}/`
    };
};

class SubPageHeader extends Component {
    render() {
        const subpageHeaderClass = classNames({
            'c-subpage-header--news-archive': this.props.isNewsArchive,
            'c-subpage-header--news-article': this.props.isNewsArticle
        });

        return (
            <header className={`c-subpage-header ${subpageHeaderClass}`}>
                <div className="c-subpage-header__top">
                    <Link to='/' className="c-subpage-header__logo">
                        <img src={this.props.logo || ''} alt="" />
                    </Link>
                </div>

                <div className="c-subpage-header__inner" style={{backgroundImage: `url(${this.props.background})`}}>
                    {this.props.parentPage &&
                        <Link className="c-subpage-header__back" to={this.props.parentPageLink}>
                            <div className="c-subpage-header__arrow"></div>
                        </Link>
                    }

                    {this.props.breadcrumbs &&
                        <div className="c-subpage-header__breadcrumbs">
                            {this.props.breadcrumbs}
                        </div>
                    }

                    {(this.props.title || this.props.description) &&
                        <div className="c-subpage-header__content">
                            {this.props.title &&
                                this.props.titleLink
                                    ? <Link to={this.props.titleLink}>
                                        <h1 className={`c-subpage-header__title is-color-${this.props.color}`}>
                                            {this.props.title}
                                        </h1>
                                    </Link>
                                    : <h1 className={`c-subpage-header__title is-color-${this.props.color}`}>
                                        {this.props.title}
                                    </h1>
                            }
                            {this.props.description &&
                                <span className={`c-subpage-header__description is-color-${this.props.color}`}>
                                    {this.props.isNewsArchive ? 'Added ' : ''}
                                    {this.props.description}
                                </span>
                            }
                        </div>
                    }
                </div>
            </header>
        );
    }
}

export default connect(mapStateToProps)(SubPageHeader);
