import React, { Component } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash.get';

import { updateLocations } from 'actions';
import { getLocations } from 'api';
import ContactPage from 'components/ContactPage/ContactPage';
import SubPageHeader from 'components/SubPageHeader/SubPageHeader';

const mapStateToProps = state => {
    // Include All group by default
    const groups = ['All'];

    // Build list of the different groups locations have
    if(state.locations) {
        for(let index = 0, length = state.locations.length; index < length; index++) {
            const location = state.locations[index];

            if(groups.indexOf(location.group) === -1) {
                groups.push(location.group);
            }
        }
    }

    return {
        groups: groups,
        headerImage:  _get(state, 'pages.contact-us.headerImage'),
        locations: _get(state, 'locations', []),
        pressContact: _get(state, 'pages.contact-us.pressContact')
    };
};

class ContactContainer extends Component {
    async componentDidMount() {
        // Fetch locations and put in store if not already present
        if(!this.props.locations || this.props.locations.length === 0) {
            const locations = await getLocations();
            this.props.dispatch(updateLocations(locations));

            this.props.loadingFinished();
        }
    }

    render() {
        return (
            <>
                <SubPageHeader
                    background={this.props.headerImage}
                    color={this.props.color}
                    title="Contact"
                    description={this.props.description}
                    parentPage={this.props.parentPage}
                    breadcrumbs={['home', 'Contact']}
                />
                <ContactPage color={this.props.color} groups={this.props.groups} locations={this.props.locations} pressContact={this.props.pressContact} />
            </>
        );
    }
}

export default connect(mapStateToProps)(ContactContainer);
