import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updatePeople } from 'actions';
import { getPeople } from 'api';
import OurPeople from 'components/OurPeople/OurPeople';

const mapStateToProps = (state => {
    return {
        people: state.people
    };
});

class OurPeopleContainer extends Component {
    async componentDidMount() {
        // Fetch people and put in store if not already present
        if(!this.props.people) {
            const people = await getPeople();
            this.props.dispatch(updatePeople(people));

            this.props.loadingFinished();
        }
    }

    render() {
        return (
            <OurPeople firstPerson={this.props.firstPerson} people={this.props.people} />
        );
    }
}

export default connect(mapStateToProps)(OurPeopleContainer);
