import React, { Component } from 'react';

import './Careers.scss';

class Careers extends Component {
    render() {
        return (
            <>
                {!this.props.careers || !this.props.careers.length
                    ? 'There are currently no vacancies available.'
                    : this.props.careers.map((career, index) =>
                        <div key={index} className="c-career">
                            <h3 className="c-career__title">{career.title}</h3>

                            <div className="c-career__info">
                                {career.location &&
                                    <span><strong>Location:</strong> {career.location}</span>
                                }
                                {career.division &&
                                    <span><strong>Division:</strong> {career.division}</span>
                                }
                                {career.requirements &&
                                    <span><strong>Requirements:</strong> {career.requirements}</span>
                                }
                                {career.document &&
                                    <a className="c-career__button c-career__button--desktop" href={career.document} download>Learn more</a>
                                }
                            </div>

                            <div className="c-career__content" dangerouslySetInnerHTML={{ __html: career.content }} />

                            {career.document &&
                                <a className="c-career__button c-career__button--mobile" href={career.document} download>Learn more</a>
                            }
                        </div>
                    )
                }
            </>
        );
    }
}

export default Careers;
